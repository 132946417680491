@use '@angular/material' as mat;
$custom-typography: mat.define-typography-config(
  $font-family: "ITV Reem",
  $headline: mat.define-typography-level(1.625em, 1.5, 400, 'ITV Reem'), // h1
  $title: mat.define-typography-level(2em, 1, 400, 'ITV Reem'), // h2
  $display-4: mat.define-typography-level(0.75em, 1, 400, 'ITV Reem'),
  $subheading-2: mat.define-typography-level(0.875em, 28px, 300, 'ITV Reem Light'), // h3 - Not using here
  $subheading-1: mat.define-typography-level(1.375em, 1.49, 300, 'ITV Reem'), //h4
  $body-2: mat.define-typography-level(1.25em, 1, 300, 'ITV Reem'),
  $body-1: mat.define-typography-level(1em, 1, 300, 'ITV Reem Light'), // base body
  $caption: mat.define-typography-level(1em, 1.36, 300, 'ITV Reem Light'),
  $button: mat.define-typography-level(1em, 1, 500),
  // Line-height must be unit-less fraction of the font-size.
  // $input: mat-typography-level(inherit, 1.125, 400)
);

@mixin identity-portal-typography($config) {
  body{
    font-size: 16px;
    letter-spacing: 0.5px !important;
  }

  .mat-body-1{
    margin: 1.33em 0;
  }

  .mat-small{
    font-size: 0.75em;
  }

  .font-lite{
    font-family: "ITV Reem Light", sans-serif;
  }

  .font-bold{
    font-family: "ITV Reem", sans-serif;
  }

  .mat-dialog-title{
    font-size: 1.625em;
  }

  .mat-title, .mat-body-1, .mat-body-2, p{
    color: $charcoal;
  }
}
