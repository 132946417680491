// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import './buttons';
@import './dialog';
@import './form';
@import './typography';
@import './stepper';
@import './stepper_content';

@include mat.core();
// @import './theme';

$itv-primary: (
    50 : $primaryblue,
    contrast: (
        50 : #ffffff,
    )
);

$itv-accent: (
  50: $activeblue,
  contrast: (
    50 : $activeblue
  )
);

$itv-warn: (
  50: $errorred,
  contrast: (
    50: $white
  )
);

$identity-portal-app-primary: mat.define-palette($itv-primary, 50, 50, 50);
$identity-portal-app-accent: mat.define-palette($itv-accent, 50, 50, 50);
$identity-portal-app-link: mat.define-palette($itv-primary, 50, 50, 50);


// The warn palette is optional (defaults to red).
$identity-portal-app-warn: mat.define-palette($itv-warn, 50, 50, 50);

// Create the theme object (a Sass map containing all of the palettes).
$identity-portal-app-theme: mat.define-light-theme($identity-portal-app-primary, $identity-portal-app-accent, $identity-portal-app-warn);


// override typography
@include mat.all-component-typographies($custom-typography);// Include theme styles for core and each component used in your app.
@include identity-portal-typography($identity-portal-app-theme);

// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($identity-portal-app-theme);
@mixin itv-portal-theme($theme) {
  // Extract the palettes you need from the theme definition.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
}

// override material components
@include identity-portal-button($identity-portal-app-theme);
@include identity-portal-stepper($identity-portal-app-theme);

.mat-progress-bar{
  margin-top: 1.5em;
}

.mat-snack-bar-container {
  text-align: center;

  &.success{
    background-color: $primaryblue;
  }

  &.error{
    background-color: $errorred;
  }
}

.mat-tab-header{
  display: none !important;
}

