.itv-dialog{
  max-width: 516px;

  section{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  .description{
    max-height: 40vh;
    overflow-y: auto;
  }


  .mat-dialog-container{
    border: solid 1px $black-50;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14);
    padding: 32px;
  }

  .mat-dialog-title{
    margin: 0 0 28px;
    word-break: break-word;
  }

  .mat-dialog-content{
    height: inherit;

    a{
      color: $charcoal;
      text-decoration: underline;
    }
  }


  .mat-dialog-actions{
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0;
    max-height: 44px;
    padding: 0;

    &.flex-end{
      justify-content: flex-end;
    }

    &.vertical-mobile{
      flex-direction: column-reverse;
      flex-wrap: nowrap;
      justify-content: flex-end;
      padding-top: 1em;

      button{
        max-width: 100%;
        width: 100%;

        &[color="accent"] {
          border: none;
          box-shadow: none;
          text-decoration: underline;
        }
      }

      @media (min-width: $screen-md-min) {
        align-items: flex-end;
        justify-content: space-between;
        margin: 0;
        padding: 0;
      }
    }

    &.mat-button-right{
      justify-content: flex-end;
    }

    &.action-bottom{
      flex-grow: 1;
    }
  }

  .dialog-toolbar{
    margin: 0 22px;
    position: absolute;
    text-align: right;
    top: -8px;
    width: 100%;

    .close{ // TODO: make this a generic button style override
      -webkit-appearance: none;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;

      &:focus{
        outline: none;
      }
    }
  }

  .mat-button-focus-overlay{
    background: transparent;
  }
}

.create-user-progress-container{
  .alert-error{
    margin-top: 0 !important;
  }
}
