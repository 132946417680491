// From Embark styleguide
$charcoal-25: rgba(45, 45, 45, 0.25);
$primaryblue: #11828A;
$accentblue: #19bec8;
$buttonblue: #138e96;
$charcoal: #2d2d2d;
$accentgreen: #867a24;
$errorred: #e32b2b;
$accentyellow: #ffaa00;
$accentPurple: #a90061;
$greytext: rgba(45, 45, 45, 0.65);
$information: rgba(132, 63, 160, 0.08);
$primarygrey: #d8d8d8;
$accentgrey: #ededed;
$backgroundgrey: #ededed;
$activeblue: #4c9aff;
$notification: #843FA0;
$white: #ffffff;
$black: #000000;
$helpgrey: #484848;
$black-50: rgba(45, 45, 45, 0.5);
$white-two: #fafafa;
$divider-grey: #e4e4e4;

$control-background-colour: $white-two;
