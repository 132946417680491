// ITV fonts
@font-face {
  font-family: "ITV Reem";
  src: url('../../assets/fonts/ITV-Reem-Regular.otf') format('opentype');
}
@font-face {
  font-family: "ITV Reem Bold";
  src: url('../../assets/fonts/ITV-Reem-Bold.otf') format('opentype')
}

@font-face {
  font-family: "ITV Reem Light";
  src: url('../../assets/fonts/ITV-Reem-Light.otf') format('opentype');
}

.help-text{
  color: $helpgrey;
  letter-spacing: 0.5px;
}

em{
  font-style: italic;
}

.alert-error{
  color: $errorred;
  font-size: 0.75em;
}
