.stepper-wizard section{
  h1, p{
    text-align: left;
  }

  h1{
    margin-bottom: 0.8125em;
  }
}

.continue{
  display: flex;
  justify-content: space-between;
  margin-top: 2.75em;
  width: 100%;

  &.flex-end{
    justify-content: flex-end;
  }
}
