@import './layout';
@import './forms';
@import './notification';
@import './typography';

.center{
  text-align: center !important;
}

footer{
  height: 3.75em;
}
