.mat-form-field{
  height: 44px;
  max-width: 100%;

  &.mat-focused{
    background-color:rgb(232, 240, 254);
  }
}


.mat-form-field-appearance-outline{
  background-color: white;
  border-radius: 4px;
  box-sizing: content-box;

  .mat-form-field-wrapper{
    height: 100%;
    margin: 0 !important;
    padding-bottom: 0;
  }

  .form-control{
    height: 100%;
    width: 98%;
    text-indent: 0;

    &:focus{
      background: transparent;
      box-shadow: none;
    }
  }

  .mat-form-field-flex{
    margin-top: 0 !important;
    padding: 0 !important;

    .mat-form-field-outline, .mat-form-field-outline-thick{
      border: none;
      top: 0;
    }

    .mat-form-field-prefix{
      left: .68em;
      top: .3em;

      .mat-icon{
        height: 1em;
        width: auto;
      }
    }

    .mat-form-field-infix{
      border: none;
      height: calc(100% - 4px);
      margin-left: .8em;
      margin-top: .2em;
      width: 95%;
    }
  }
}


.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: $activeblue !important;
}

.mat-form-field-flex{
  height: inherit;
  padding: 0;

  &:focus{
    .mat-form-field-outline-thick {
      color: $activeblue;
    }
  }

  &:hover{
    .mat-form-field-outline{
      border: none;
    }

    .mat-form-field-outline-thick {
      color: $activeblue;
    }
  }
}

mat-form-field-appearance-outline .mat-form-field-flex{
  padding: 0 !important;

  &:hover{
    border: none !important;
  }
}

.mat-form-field-infix{
  padding: 0 !important;
  text-indent: 0;
}

.mat-form-field-suffix{
  top: .55em;
}


.mat-datepicker-toggle{
  color: $charcoal !important;
}
