@import '../colours.scss';

$inputComplete: #f5f5f5;

.alert-error{
  margin-top: 1em;

  .mat-icon{
    font-size: 14px;
    height: 14px;
    width: auto;
  }
}

.form {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  padding: 1.5em;

  &-control {
    height: 45px;
  }

  select, input, .datePicker{
    width: 100%;
  }

  label{
    color: $charcoal;
    font-size: 1em;
  }

  input{
    text-indent: 1em;
  }
}

.required{
  color: $errorred;
  font-size: 1.2em;
  font-weight: bold;
}

.wiz-control-container {
  margin-bottom: 24px;

  label {
    display: block;
  }

  .wiz-form-control, .mat-form-field-outline {
    height: 44px;
    border-radius: 4px;
    border: solid 1px $charcoal-25;
    margin-bottom: 0;

    &.ng-touched{
    background-color: $inputComplete;
    border: solid 1px #fff;
    }

    &.ng-touched.ng-invalid {
      border-color: $errorred;
      background-color: #FFF6F6;
    }

    &:hover{
      border: solid 2px $activeblue;
    }

    &:focus {
      background-color: #EFF6FF;
      outline-color: $activeblue;
      outline-width: 2px;
      -moz-outline-radius: 6px;
      border-radius: 6px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
      border: solid 2px $activeblue;
    }
  }

}

input, select{
  &.ng-touched.ng-dirty{
    background-color: $inputComplete;
    border: solid 1px #fff;
  }

  &.ng-touched.ng-invalid, &.ng-touched.itv-invalid {
    border-color: $errorred !important;
    background-color: #FFF6F6 !important;
  }

  &:hover{
    border: solid 2px $activeblue;
  }

  &:focus {
    background-color: #EFF6FF;
    outline-color: $activeblue;
    outline-width: 2px;
    -moz-outline-radius: 6px;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    border: solid 2px $activeblue;
  }
}

form, .form{
  hr{
    color: #b9b9b9;
    border-top-width: 2px;
    margin: 1.75em 0;
    width: 100%;
  }
}

.error-section {
  margin-bottom: 15px;
}

.form-row{
  padding-top: 1.143em;

  .form-group{
    margin-bottom: 0;
  }

  &:first-of-type{
    padding-top: 0;
  }
}
