@mixin identity-portal-stepper($config) {
  /*
  General Stepper styles
  ============================================
  */
  .mat-step-icon{
    background-color: transparent !important;
    border: none;
    color: $primaryblue;
  }

  .mat-drawer-content{
    min-height: 100vh;
    overflow-y: auto;
  }

  // TODO: unify make these a mixins so styles are not repeating. See resusable-elements.scss
  .inactive-circle{
    background-color: $charcoal-25;
    border-radius: 10px;
    height: 12px;
    width: 12px;
  }

  .active-circle{
    background-color: $primaryblue;
    border-radius: 10px;
    height: 12px;
    width: 12px;
  }


  /*
  Vertical Stepper styles
  ============================================
  */

  .mat-stepper-vertical{
    background-color: transparent !important;
    padding-top: 2.9em;
  }

  .mat-vertical-stepper-header{
    padding-right: 0;

    .mat-step-icon{
      margin-right: 4px !important;
    }
  }


  .mat-step-label-selected{
    .mat-step-text-label{
      font-family: 'ITV Reem', sans-serif;
    }
  }


  .mat-step-text-label{
    font-family: 'ITV Reem Light', sans-serif;
    font-size: 14px;
    text-overflow: unset;
    overflow: visible;
  }
}


.stepper-wizard {
  padding-top: 2.5em;

  .mat-step-header {
    border: none !important;
    cursor: default;

    &:hover{
      background: none !important;
    }
  }

  .mat-step-icon {
    background-color: transparent !important;
    border: none;
    color: $primaryblue;

    .mat-step-icon-content{
      left: 46%;
    }
  }

  .mat-step-icon-state-done + .mat-stepper-vertical-line {
    &::before{
      border-color: $primaryblue;
    }
  }

  .mat-vertical-stepper-header {
    padding-left: 0;
    padding-right: 0;
  }

  // Vertical line on the left of the wizard
  .mat-stepper-vertical-line::before {
    content: '';
    position: absolute;
    top: -31px;
    bottom: -31px;
    left: -4px;
    border-left-width: 6px;
    border-left-style: solid;
    border-color: $charcoal-25;
  }

  .mat-vertical-content-container {
    margin-left: 12px;

    // Vertical line on the left of the wizard
    .mat-stepper-vertical-line::before {
      content: '';
      position: absolute;
      top: -31px;
      bottom: -31px;
      left: -4px;
      border-left-width: 6px;
      border-left-style: solid;
      // border-color: $primaryblue;
      border-color: $charcoal-25;

      .mat-vertical-content {
        margin-left: 125px;
        max-width: 690px;
      }
    }
  }

}

.mat-stepper-horizontal-line {
  border-top-color: $primaryblue;
}

.mat-horizontal-content-container {
  padding: 0;
}

.emp-type-container-small {
  .mat-expansion-panel-body {
    padding-left: 5px !important;
  }
}

  /*
  Horizontal Stepper styles
  ============================================
  */

  .mat-stepper-horizontal{
    background: none !important;
  }

  .mat-horizontal-stepper-header{
    .ng-star-inserted.inactive-circle{
      height: 1.285em;
      width: 1.285em;
      background-color: rgba(#d8d8d8, 1) !important;
    }

    .mat-step-text-label{
      display: none;
    }
  }

  .mat-stepper-horizontal-line{
    border-top-width: 3px !important;

    &.mat-step-icon-state-done{
      border-top-color: #138e96;
    }
  }
