@import './assets/css/bootstrap.min.css';
@import './scss/colours.scss';
@import './scss/reusable-components/reusable-components.scss';
@import './scss/variables.scss';
@import 'scss/overrides.scss';

/* You can add global styles to this file, and also import other style files */


html,
body {
  background-color: $backgroundgrey;
  font-size: 16px;
  height: 100%;
}

body{
  padding-bottom: 8em;
}
