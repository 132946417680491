@mixin identity-portal-button($config) {
  button{
    outline: none !important;
    height: 44px;
    max-height: 44px;
    outline-style: none !important;

    &[color=link]{
      color: $primaryblue;
      font-family: 'ITV Reem', sans-serif !important;
    }

    &.icon-button{
      text-align: left;
      padding: 0 24px 0 0;

      .mat-icon{
        display: inline-block;
        line-height: 0;
        height: 24px;
        padding: 10px 12px !important;
        width: auto;
      }
    }
  }

  .mat-button-focus-overlay{
    display: none;
  }

  .mat-button-toggle-group{
    border: none;
  }

  .mat-button-ripple.mat-ripple{
    width: auto;
  }

  .mat-button-toggle{
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.32);
    padding-bottom: 0.75em;


    &.mat-button-toggle-checked, &.cdk-keyboard-focused{
      background-color: $white;
      border: solid 1px $activeblue !important;
    }

    &:hover{
      border: solid 1px $activeblue !important;
    }
  }

  .mat-flat-button{
    border-radius: 4px !important;
    padding: 2px 20px !important;
    text-align: center;
    width: auto;

    &[color=warning] {
      background-color: $errorred;
      color: white;
    }

    &[color=transparent] {
      background-color: transparent;
      color: $primaryblue;

      &:hover{
        background-color: $accentgrey;
      }
    }

    &.mat-accent{
      background-color: transparent;
      color: $primaryblue;
      width: auto;
      border: 1.5px solid $primaryblue;
    }

    &.mat-primary{
      width: auto;
      border: 1.5px solid $primaryblue;
    }

    &:disabled{
      border: none;
      box-shadow: none;
      color: #fff !important;
    }

    &:hover{
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
    }

    &:active, &.cdk-keyboard-focused{
      border: 4px solid $accentyellow;
    }
  }

  .mat-stroked-button{
    border: 2px solid $primaryblue !important;

    &[color=warn]{
      border:  2px solid $errorred !important;
    }
  }


  .mat-raised-button{
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.5);
  }

  .mat-radio-container{
    height: 1.5em !important;
    width: 1.5em !important;
  }

  .mat-radio-outer-circle{
    border: 1px solid #b9b9b9;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(1);
  }
}
